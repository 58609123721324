// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"
import Encoder from "../components/encoder"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Base64Encoder = () => (
  <Layout>
    <SEO title="Base64 Encoder Online - Convert any String to Base 64 " description={"we can use this tool to convert any string to base64 fast .We can use this tool to concode any url to base64 as well"} />

    <div className="container">
    <h1>Base 64 Encoder Online - Convert any String to base64 </h1>
    <Encoder />
    <h2>What is Base64 </h2>
    <p>Base64 is a string encoding method that uses a 64-bit alphabet. This method is commonly used to encode binary data in an efficient manner. 
    It was originally developed by IBM as a way to convert binary data into text, but now also found its way into other forms of encoding. In this article, we will discuss everything you need to know about Base64 and its encoding method implementation.

    </p>

    <h2>What is base64 Enocder</h2>
    <p>Base64 encoding is a method for encoding binary data into an ASCII string. It's used to encode binary data in a way that can be sent through email, used as file names, or included in text. 
    Base64 also works well for printing large amounts of binary data on paper--for example, when you want to print out a PDF version of your website's page layout files (or even just one page).

    </p>

    <h2>How to Use base64 Encoder tool</h2>
    <p>
    You can use the online base64 encoder to encode text.
   

    </p>
    <ul>
        <li>
        Enter any string on the textbox above.
        </li>
        <li>Now Click On Encode button below , once we click on the encode button and encoded text appears </li>
        <li>Right Click and Copy the text in from encoded textfiled . Now you have the base64 version of the text in your clipboard </li>
        
    </ul>

    <p>You can also have a look at our Base64 Decoder tool and <a href="https://thelinuxterminal.com/cron-expression-generator">Cron Expression Generator tool</a></p>
    </div>
    
     
  </Layout>
)

export default Base64Encoder
