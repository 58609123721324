import React from 'react';
import base64 from 'base-64';
import { Form } from 'react-bootstrap';

const convertBase64 = (input) => {
    return base64.encode(input);
}
class Encoder extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: '', enteredText: '' };
    }
    encode = () => {
        console.log('dec')
        this.setState({ data: convertBase64(this.state.enteredText) });

    }

    handlechange = (ev) => {

        console.log(ev.target.value);
        this.setState({ enteredText: ev.target.value });

    }
    render() {
        return (
            <div>
                <div class="form-group">
                    <label for="exampleFormControlTextarea1">Enter Data to be Converted to Base64</label>
                    <textarea class="form-control" onChange={this.handlechange} id="exampleFormControlTextarddea1" rows="3"></textarea>
                </div>
                
                <button onClick={this.encode} className="button -primary">Encode &rarr;</button>

                <Form>
                    <br></br>
                    <Form.Group controlId="exampleForm.ControlTextarea1">

                        <Form.Control as="textarea" value={this.state.data} placeholder="Base 64 Output will be shown here" readOnly rows={3} />
                    </Form.Group>
                </Form>
            </div>
        );
    }
}


export default Encoder;